import React, { useState, useEffect } from 'react';
import { fetchRanking, fetchActiveSeason } from '../utils/api';
import '../styles/rankingModal.css';
import { useAuth } from '../contexts/AuthContext';

const RankingModal = ({ show, onClose }) => {
  const { userAccount } = useAuth(); // Get the connected user's account
  const [ranking, setRanking] = useState([]);
  const [activeSeason, setActiveSeason] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch updated ranking
        const rankingData = await fetchRanking();
        setRanking(rankingData);

        // Fetch active season data
        const season = await fetchActiveSeason();
        if (season) {
          setActiveSeason(season);
          const interval = setInterval(() => {
            const now = new Date();
            const endTime = new Date(season.endTime);
            const timeDiff = endTime - now;
            if (timeDiff > 0) {
              const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
              const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
              const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
              const seconds = Math.floor((timeDiff / 1000) % 60);
              setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
            } else {
              clearInterval(interval);
              setTimeRemaining('Season ended');
            }
          }, 1000);
        } else {
          setTimeRemaining('No active season');
        }
      } catch (err) {
        console.error('Failed to fetch data:', err);
        setTimeRemaining('Failed to load data');
      }
    };

    if (show) {
      fetchData();
    }
  }, [show]); // This effect will run every time `show` changes

  if (!show) return null;

  const filteredRanking = ranking.filter(user => user.xp > 0);

  const getNFTPrizesForPosition = (index) => {
    const prizes = [];
    if (!activeSeason) return prizes;

    if (index + 1 <= 50 && activeSeason.nftPrizes.fiftieth) {
      prizes.push(...activeSeason.nftPrizes.fiftieth);
    }
    if (index + 1 <= 30 && activeSeason.nftPrizes.thirtieth) {
      prizes.push(...activeSeason.nftPrizes.thirtieth);
    }
    if (index + 1 <= 10 && activeSeason.nftPrizes.tenth) {
      prizes.push(...activeSeason.nftPrizes.tenth);
    }
    if (index + 1 === 3 && activeSeason.nftPrizes.third) {
      prizes.push(...activeSeason.nftPrizes.third);
    }
    if (index + 1 === 2 && activeSeason.nftPrizes.second) {
      prizes.push(...activeSeason.nftPrizes.second);
    }
    if (index + 1 === 1 && activeSeason.nftPrizes.first) {
      prizes.push(...activeSeason.nftPrizes.first);
    }

    return prizes;
  };

  const distributeCoins = (totalCoins, index) => {
    const rewardPercentages = [
      0.15, 0.11, 0.08, 0.06, 0.05, 0.045, 0.04, 0.035, 0.03, 0.025,
      0.02, 0.018, 0.016, 0.014, 0.012, 0.01, 0.0095, 0.009, 0.0085, 0.008,
      0.0075, 0.007, 0.0068, 0.0066, 0.0064, 0.0062, 0.006, 0.0058, 0.0056, 0.0054,
      0.0052, 0.005, 0.0048, 0.0046, 0.0044, 0.0042, 0.004, 0.0038, 0.0036, 0.0034,
      0.0032, 0.003, 0.0028, 0.0026, 0.0024, 0.0022, 0.002, 0.0018, 0.0017, 0.0016
    ];

    return Math.floor(totalCoins * (rewardPercentages[index] || 0));
  };

  const generatePlaceholders = () => {
    const placeholders = [];
    const placeholderCount = 50 - filteredRanking.length;
    for (let i = 0; i < placeholderCount; i++) {
      placeholders.push({
        walletAddress: `Empty Slot`,
        level: '---',
        wins: '---',
        second_places: '---',
        third_places: '---',
        isPlaceholder: true
      });
    }
    return placeholders;
  };

  return (
    <>
      <div className="ranking-overlay" onClick={onClose}></div>
      <div className="ranking-modal">
        <div className="ranking-modal-content">
          <span className="close-ranking" onClick={onClose}>✖</span>
          <h2 className="ranking-title">Ranking - {activeSeason ? activeSeason.name : 'No Active Season'}</h2>
          <p className="season-timer">Season ends in: {timeRemaining}</p>
          <ul>
            {[...filteredRanking, ...generatePlaceholders()].map((user, index) => {
              const nftPrizes = getNFTPrizesForPosition(index);
              const rewardCoins = distributeCoins(activeSeason ? activeSeason.totalRacingCoins : 0, index);

              // Check if the current user is the connected user
              const isActiveUser = user.walletAddress === userAccount;

              return (
                <li key={index} className={`ranking-item ${isActiveUser ? 'active-user' : ''}`}>
                  <span className="ranking-index">{index + 1}. {user.walletAddress}</span>
                  <div className="ranking-info">
                    <div className="ranking-slot">
                      <span>Level:</span>
                      <span>{user.level}</span>
                    </div>
                    <div className="ranking-slot">
                      <span>Wins:</span>
                      <span>{user.wins}</span>
                    </div>
                    <div className="ranking-slot">
                      <span>2nd:</span>
                      <span>{user.second_places}</span>
                    </div>
                    <div className="ranking-slot">
                      <span>3rd:</span>
                      <span>{user.third_places}</span>
                    </div>
                  </div>

                  <div className="rewards-section">
                    <h4 className="rewards-title">Rewards Season</h4>
                    <div className="reward-items">
                    <div className="reward-coins">🏆 {rewardCoins.toFixed(2)} Coins</div>
                      <div className="ranking-nfts">
                        {nftPrizes.map((nft, i) => (
                          <div key={i} className="nft-slot-ranking">
                            <img src={`https://ipfs.io/ipfs/${nft.img}`} alt={nft.name} className="nft-image-ranking" />
                            <div className="nft-amount-ranking">x{nft.amount}</div>
                            <div className="nft-name-ranking">
                              {nft.name.length > 14 ? `${nft.name.substring(0, 14)}...` : nft.name}
                            </div>
                            <div className="custom-nft-tooltip">
                              <div className="custom-nft-tooltip-header">Attributes</div>
                              <ul className="custom-nft-tooltip-list">
                                {[
                                  ...nft.attributes
                                    .filter(attr => attr.trait_type === 'name')
                                    .map((attr, j) => (
                                      <li key={`name-${j}`} className="custom-nft-tooltip-item">
                                        <strong className="attribute-name">{attr.trait_type}</strong>: <span className="attribute-value">{attr.value}</span>
                                      </li>
                                    )),
                                  ...nft.attributes
                                    .filter(attr => attr.trait_type !== 'img' && attr.trait_type !== 'name')
                                    .map((attr, j) => (
                                      <li key={j} className="custom-nft-tooltip-item">
                                        <strong className="attribute-name">{attr.trait_type}</strong>: <span className="attribute-value">{attr.value}</span>
                                      </li>
                                    ))
                                ]}
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default RankingModal;
