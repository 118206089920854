import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { fetchNFTs, checkStake, checkCarInRace } from '../utils/api';
import '../styles/nftSelector.css';
import { TailSpin } from 'react-loader-spinner';

const NFTSelector = ({ selectedCar, handleSelectNFT, setShowSelectCar, setNotificationMessage, setShowNotification }) => {
    const { userAccount } = useAuth();
    const [nfts, setNfts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [isLoading, setIsLoading] = useState(false);
    const [stakedNfts, setStakedNfts] = useState([]);
    const [refreshCount, setRefreshCount] = useState(0); // Contador de cliques no botão de refresh
    const [isCooldown, setIsCooldown] = useState(false); // Estado para gerenciar o cooldown
    const [cooldownTime, setCooldownTime] = useState(0); // Tempo restante do cooldown

    const MAX_REFRESH_CLICKS = 5; // Número máximo de cliques antes do cooldown
    const COOLDOWN_DURATION = 10; // Duração do cooldown em segundos
    const oneDay = 24 * 60 * 60 * 1000;

    useEffect(() => {
        if (userAccount) {
            loadNfts();
            checkRefreshClicks(); // Verificar cliques de refresh no início
        }
    }, [userAccount]);

    useEffect(() => {
        let cooldownInterval;

        if (isCooldown && cooldownTime > 0) {
            // Atualiza o tempo de cooldown a cada segundo
            cooldownInterval = setInterval(() => {
                setCooldownTime(prevTime => prevTime - 1);
            }, 1000);
        } else if (cooldownTime <= 0 && isCooldown) {
            // Quando o cooldown termina
            clearInterval(cooldownInterval);
            setIsCooldown(false);
            setCooldownTime(0);
            setRefreshCount(0); // Resetar o contador de cliques
            localStorage.removeItem('refreshClicks_nftSelector'); // Remover contagem de cliques após o cooldown
        }

        return () => clearInterval(cooldownInterval); // Limpa o intervalo ao desmontar o componente
    }, [isCooldown, cooldownTime]);

    const checkRefreshClicks = () => {
        const refreshClicks = JSON.parse(localStorage.getItem('refreshClicks_nftSelector')) || [];
        const now = Date.now();
        const validClicks = refreshClicks.filter(clickTime => now - clickTime < 60000); // 1 minuto
        localStorage.setItem('refreshClicks_nftSelector', JSON.stringify(validClicks));
        setRefreshCount(validClicks.length);
    };

    const loadNfts = async () => {
        setIsLoading(true);
        const storageKey = `nfts_${userAccount}`;
        const cachedData = localStorage.getItem(storageKey);
        const cacheTimestamp = localStorage.getItem(`${storageKey}_timestamp`);

        if (cachedData && cacheTimestamp && (Date.now() - cacheTimestamp < oneDay)) {
            // Usar dados armazenados se não tiverem expirado
            setNfts(JSON.parse(cachedData));
            setIsLoading(false);
        } else {
            try {
                const staked = await checkStake();
                setStakedNfts(staked);

                const userNfts = await fetchNFTs(userAccount);
                const availableNfts = await Promise.all(
                    userNfts.map(async (nft) => {
                        const isInRace = await checkCarInRace(nft.asset_id);
                        return {
                            ...nft,
                            inRace: isInRace.inRace,
                            inStake: staked.includes(nft.asset_id),
                        };
                    })
                );
                setNfts(availableNfts);

                // Salvar os dados e o timestamp no localStorage
                localStorage.setItem(storageKey, JSON.stringify(availableNfts));
                localStorage.setItem(`${storageKey}_timestamp`, Date.now());
            } catch (error) {
                console.error('Erro ao carregar NFTs:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleRefresh = () => {
        if (isCooldown) {
            return;
        }

        const now = Date.now();
        const refreshClicks = JSON.parse(localStorage.getItem('refreshClicks_nftSelector')) || [];

        if (refreshCount >= MAX_REFRESH_CLICKS) {
            setIsCooldown(true);
            setCooldownTime(COOLDOWN_DURATION);
            localStorage.setItem('refreshClicks_nftSelector', JSON.stringify(refreshClicks));
        } else {
            // Limpar os dados do localStorage e recarregar
            localStorage.removeItem(`nfts_${userAccount}`);
            localStorage.removeItem(`nfts_${userAccount}_timestamp`);
            loadNfts();

            const updatedClicks = [...refreshClicks, now];
            localStorage.setItem('refreshClicks_nftSelector', JSON.stringify(updatedClicks));
            setRefreshCount(refreshCount + 1);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentNfts = nfts.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleSelect = (nft) => {
        if (nft.inRace) {
            setNotificationMessage('Car is currently in a race.');
            setShowNotification(true);
        } else if (nft.inStake) {
            setNotificationMessage('Car is currently staked.');
            setShowNotification(true);
        } else {
            handleSelectNFT(nft);
        }
    };

    return (
        <>
            <div className="nft-selector-overlay" onClick={() => setShowSelectCar(false)}></div>
            <div className="nft-selector-modal">
                <div className="nft-selector-header">
                    <h2>Select Your NFT</h2>
                    <button className="nft-selector-close-button" onClick={() => setShowSelectCar(false)}>X</button>
                </div>
                <button 
                    className="nft-selector-refresh-button" 
                    onClick={handleRefresh}
                    disabled={isCooldown}
                >
                    {isCooldown ? `Aguarde... ${cooldownTime}s` : 'Refresh'}
                </button>
                <div className="nft-selector-grid-container">
                    {isLoading ? (
                        <div className="nft-selector-loading-container">
                            <div className="nft-selector-spinner-wrapper">
                                <TailSpin height="100" width="100" color="#00ff00" ariaLabel="loading" />
                            </div>
                        </div>
                    ) : (
                        <div className="nft-selector-grid">
                            {currentNfts.map((nft) => (
                                <div
                                    key={nft.asset_id}
                                    className={`nft-selector-item ${selectedCar && selectedCar.asset_id === nft.asset_id ? 'nft-selector-selected' : ''} ${nft.inRace || nft.inStake ? 'inactive' : ''}`}
                                    onClick={() => handleSelect(nft)}
                                >
                                    {nft.template && nft.template.immutable_data ? (
                                        <>
                                            <div className="nft-selector-image-container">
                                                <img src={`https://ipfs.io/ipfs/${nft.template.immutable_data.img2}`} alt={nft.template.immutable_data.name} className="nft-selector-image" />
                                            </div>
                                            <div className="nft-selector-name">{nft.template.immutable_data.name}</div>
                                            <div className="nft-selector-id">{nft.asset_id}</div>
                                            <div className="nft-selector-rarity">{nft.template.immutable_data['Car Rarity']}</div>
                                            {(nft.inRace || nft.inStake) && (
                                                <div className={`status-overlay ${nft.inRace ? 'in-race' : 'in-stake'}`}>
                                                    {nft.inRace ? 'Racing' : 'Staked'}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="nft-selector-error">NFT data not available</div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="nft-selector-pagination">
                    {[...Array(Math.ceil(nfts.length / itemsPerPage)).keys()].map((number) => (
                        <button
                            key={number}
                            onClick={() => paginate(number + 1)}
                            className={`nft-selector-pagination-button ${currentPage === number + 1 ? 'nft-selector-pagination-button-active' : ''}`}
                        >
                            {number + 1}
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};

export default NFTSelector;
