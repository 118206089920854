import React, { useEffect, useState } from 'react';
import { checkCarRaceStatus } from '../utils/api';

const CarDisplay = ({ selectedCar, handleSelectCar, setShowItemSelection, equippedNFTs, setEquippedNFTs, setNotificationMessage, setShowNotification }) => {
  const [raceStatus, setRaceStatus] = useState(null);

  useEffect(() => {
    const fetchRaceStatus = async () => {
      if (selectedCar) {
        try {
          const status = await checkCarRaceStatus(selectedCar.asset_id);
          setRaceStatus(status[0]);
        } catch (error) {
          console.error('Failed to fetch race status:', error);
        }
      }
    };

    // Chama a função apenas uma vez quando o carro é selecionado
    fetchRaceStatus();
  }, [selectedCar]); // O efeito só será executado quando `selectedCar` mudar

  const handleRemoveNFT = (index) => {
    setEquippedNFTs(prevNFTs => prevNFTs.filter((_, i) => i !== index));
  };

  const handleEquipNFTClick = (e) => {
    e.stopPropagation();
    if (equippedNFTs.length >= 3) {
      setNotificationMessage('Você não pode equipar mais de 3 itens.');
      setShowNotification(true);
      return;
    }
    setShowItemSelection(true);
  };

  return (
    <div className="car-display">
      <div className="select-car-overlay">Select Car</div>
      <div className="car-placeholder" onClick={handleSelectCar}>
        {selectedCar ? (
          <>
            <img src={`https://ipfs.io/ipfs/${selectedCar.template.immutable_data.img2}`} alt="Selected Car" className="car-image" />
            {raceStatus && raceStatus.ongoing && (
              <div className="race-status">In Use</div>
            )}
          </>
        ) : (
          <img src="../assets/carmodelselected.png" alt="Car Placeholder" className="car-image" />
        )}
      </div>
      <div className="items-area">
        <div className="items-button large-text" onClick={handleEquipNFTClick}>
          <span className="items-text">Items</span>
        </div>
        <div className="equipped-nfts">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="equipped-nft">
              {equippedNFTs[index] ? (
                <>
                  <img src={equippedNFTs[index].data.img ? `https://ipfs.io/ipfs/${equippedNFTs[index].data.img}` : '/placeholder.png'} alt="Equipped NFT" className="nft-image" />
                  <button className="remove-nft-button" onClick={() => handleRemoveNFT(index)}>X</button>
                </>
              ) : (
                <div className="empty-slot" onClick={handleEquipNFTClick}>+</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarDisplay;
