import React, { useState, useEffect } from 'react';
import { createSeason, updateSeason, deleteSeason, fetchSeasons, resetUserRankings, distributeSeasonRewardsManually, updateSeasonStatus } from '../../utils/api';
import Cookies from 'js-cookie';

const SeasonManagement = () => {
  const [newSeason, setNewSeason] = useState({
    name: '',
    startTime: '',
    endTime: '',
    totalRacingCoins: 0,
    nftPrizes: {
      first: [],
      second: [],
      third: [],
      tenth: [],
      thirtieth: [],
      fiftieth: []
    }
  });
  const [seasons, setSeasons] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState('');
  const [nfts, setNfts] = useState([]);
  const [schemas, setSchemas] = useState([]); // Lista de schemas identificados
  const [selectedSchema, setSelectedSchema] = useState(''); // Schema selecionado
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [showTemplateSelectorForSeason, setShowTemplateSelectorForSeason] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingSeasonId, setEditingSeasonId] = useState(null);
  const [showConfirmReset, setShowConfirmReset] = useState(false);

  useEffect(() => {
    const loadSeasons = async () => {
      const token = Cookies.get('authToken');
      const fetchedSeasons = await fetchSeasons(token);
      setSeasons(fetchedSeasons);
    };
    loadSeasons();
  }, []);

  const handleSeasonChange = (event) => {
    const { name, value } = event.target;
    setNewSeason({ ...newSeason, [name]: value });
  };

  const handleCreateOrUpdateSeason = async () => {
    const token = Cookies.get('authToken');
    if (isEditing) {
      await updateSeason(editingSeasonId, newSeason, token);
    } else {
      await createSeason(newSeason, token);
    }
    resetForm();
    const seasonsData = await fetchSeasons(token);
    setSeasons(seasonsData);
  };

  const handleEditSeason = (seasonId, seasonData) => {
    setIsEditing(true);
    setEditingSeasonId(seasonId);
    setSelectedPosition('');
    setNewSeason({
      ...seasonData,
      startTime: new Date(seasonData.startTime).toISOString().slice(0, 16),
      endTime: new Date(seasonData.endTime).toISOString().slice(0, 16),
    });
  };

  const handleDeleteSeason = async (seasonId) => {
    const token = Cookies.get('authToken');
    await deleteSeason(seasonId, token);
    const seasonsData = await fetchSeasons(token);
    setSeasons(seasonsData);
    resetForm();
  };

  const resetForm = () => {
    setNewSeason({
      name: '',
      startTime: '',
      endTime: '',
      totalRacingCoins: 0,
      nftPrizes: {
        first: [],
        second: [],
        third: [],
        tenth: [],
        thirtieth: [],
        fiftieth: []
      }
    });
    setIsEditing(false);
    setEditingSeasonId(null);
  };

  const handleSelectTemplateForPosition = (template) => {
    if (selectedPosition) {
      const attributes = Object.entries(template.immutable_data).map(([key, value]) => ({
        trait_type: key,
        value: value,
      }));

      const updatedPrizes = {
        ...newSeason.nftPrizes,
        [selectedPosition]: [
          ...newSeason.nftPrizes[selectedPosition],
          {
            template_id: template.template_id,
            name: template.immutable_data.name,
            img: template.immutable_data.img,
            amount: 1,
            attributes: attributes // Include attributes here
          }
        ]
      };
      setNewSeason({ ...newSeason, nftPrizes: updatedPrizes });
      setShowTemplateSelectorForSeason(false);
    }
  };

  const handleNftAmountChange = (position, index, value) => {
    const updatedNfts = [...newSeason.nftPrizes[position]];
    updatedNfts[index].amount = value;
    setNewSeason({
      ...newSeason,
      nftPrizes: {
        ...newSeason.nftPrizes,
        [position]: updatedNfts
      }
    });
  };

  const handleRemoveTemplateForPosition = (position, templateId) => {
    const updatedPrizes = {
      ...newSeason.nftPrizes,
      [position]: newSeason.nftPrizes[position].filter(nft => nft.template_id !== templateId),
    };
    setNewSeason({ ...newSeason, nftPrizes: updatedPrizes });
  };

  // Fetch NFTs and schemas
  const fetchTemplates = async () => {
    setLoadingTemplates(true);
    try {
      const response = await fetch(`https://wax.api.atomicassets.io/atomicassets/v1/templates?collection_name=pixelracesgo`);
      const data = await response.json();

      // Identificar os schemas únicos
      const schemaSet = new Set(data.data.map((nft) => nft.schema.schema_name));
      setSchemas([...schemaSet]);

      setNfts(data.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setLoadingTemplates(false);
    }
  };

  const handleSchemaChange = (e) => {
    setSelectedSchema(e.target.value);
  };

  const filteredNfts = nfts.filter((nft) => nft.schema.schema_name === selectedSchema || selectedSchema === '');

  // Função para resetar rankings de usuários
  const handleResetRankings = async () => {
    try {
      const token = Cookies.get('authToken');
      await resetUserRankings(token);
      alert('Rankings resetados com sucesso!');
      setShowConfirmReset(false);
    } catch (error) {
      console.error('Erro ao resetar os rankings:', error);
      alert('Erro ao resetar os rankings.');
    }
  };

  // Function to distribute rewards
  const handleDistributeRewards = async (seasonId) => {
    if (window.confirm('Are you sure you want to distribute rewards for this season?')) {
      try {
        await distributeSeasonRewardsManually(seasonId);
        alert('Rewards distributed successfully.');
      } catch (error) {
        console.error('Error distributing rewards:', error);
        alert('Error distributing rewards.');
      }
    }
  };

  // Function to update season status
  const handleToggleSeasonStatus = async (seasonId, currentStatus) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
    if (window.confirm(`Are you sure you want to ${newStatus === 'active' ? 'activate' : 'deactivate'} this season?`)) {
      try {
        await updateSeasonStatus(seasonId, newStatus);
        alert(`Season ${newStatus === 'active' ? 'activated' : 'deactivated'} successfully.`);
        const seasonsData = await fetchSeasons(Cookies.get('authToken'));
        setSeasons(seasonsData);
      } catch (error) {
        console.error(`Error updating season status to ${newStatus}:`, error);
        alert(`Error updating season status to ${newStatus}.`);
      }
    }
  };

  return (
    <div>
      <h3>Season Management</h3>
      <input name="name" value={newSeason.name} onChange={handleSeasonChange} placeholder="Season Name" />
      <input type="datetime-local" name="startTime" value={newSeason.startTime} onChange={handleSeasonChange} />
      <input type="datetime-local" name="endTime" value={newSeason.endTime} onChange={handleSeasonChange} />
      <input name="totalRacingCoins" type="number" value={newSeason.totalRacingCoins} onChange={handleSeasonChange} placeholder="Total Racing Coins" />

      {/* Select Position for NFTs */}
      <div>
        <label>Select Position: 
          <select value={selectedPosition} onChange={(e) => setSelectedPosition(e.target.value)}>
            <option value="">Select Position</option>
            <option value="first">1st Place</option>
            <option value="second">2nd Place</option>
            <option value="third">3rd Place</option>
            <option value="tenth">10th Place</option>
            <option value="thirtieth">30th Place</option>
            <option value="fiftieth">50th Place</option>
          </select>
        </label>
        <button onClick={() => setShowTemplateSelectorForSeason(true)}>Add NFT Template</button>
      </div>

      {/* Display Selected NFTs for Each Position */}
      {Object.keys(newSeason.nftPrizes).map((position) => (
        <div key={position}>
          <h4>{position.charAt(0).toUpperCase() + position.slice(1)} Place NFTs</h4>
          {newSeason.nftPrizes[position].map((nft, index) => (
            <div key={nft.template_id}>
              <img src={`https://ipfs.io/ipfs/${nft.img}`} alt={nft.name} style={{ width: '100px' }} />
              <div>{nft.name}</div>
              <label>
                Amount:
                <input 
                  type="number" 
                  value={nft.amount} 
                  onChange={(e) => handleNftAmountChange(position, index, e.target.value)} 
                />
              </label>
              <button onClick={() => handleRemoveTemplateForPosition(position, nft.template_id)}>Remove</button>
            </div>
          ))}
        </div>
      ))}

      {/* NFT Template Selection Modal */}
      {showTemplateSelectorForSeason && (
        <div className="template-selector-modal">
          <button onClick={() => setShowTemplateSelectorForSeason(false)}>Close</button>
          <button onClick={fetchTemplates} disabled={loadingTemplates}>
            {loadingTemplates ? 'Loading...' : 'Fetch Templates'}
          </button>

          {/* Filtro de schemas */}
          <div>
            <label>
              Select Schema:
              <select value={selectedSchema} onChange={handleSchemaChange}>
                <option value="">All Schemas</option>
                {schemas.map((schema) => (
                  <option key={schema} value={schema}>{schema}</option>
                ))}
              </select>
            </label>
          </div>

          <div className="nft-grid">
            {filteredNfts.map((nft, index) => (
              <div key={index} onClick={() => handleSelectTemplateForPosition(nft)}>
                <img src={`https://ipfs.io/ipfs/${nft.immutable_data.img}`} alt={nft.immutable_data.name} style={{ width: '100px' }} />
                <div>{nft.immutable_data.name}</div>
                <div>Schema: {nft.schema.schema_name}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Button label changes based on editing mode */}
      <button onClick={handleCreateOrUpdateSeason}>
        {isEditing ? 'Update Season' : 'Create Season'}
      </button>

      {/* List of Seasons */}
      <ul>
        {seasons.map((season) => (
          <li key={season._id}>
            <div>
              <strong>{season.name}</strong>
              <div>Status: {season.status}</div> {/* Display season status */}
              <div>Start: {new Date(season.startTime).toLocaleString()}</div>
              <div>End: {new Date(season.endTime).toLocaleString()}</div>
              <div>Total Racing Coins: {season.totalRacingCoins}</div>
            </div>
            <button onClick={() => handleEditSeason(season._id, season)}>Edit</button>
            <button onClick={() => handleDeleteSeason(season._id)}>Delete</button>
            {/* Add button for distributing rewards and toggling status */}
            <button onClick={() => handleDistributeRewards(season._id)}>Distribute Rewards</button>
            <button onClick={() => handleToggleSeasonStatus(season._id, season.status)}>
              {season.status === 'active' ? 'Deactivate' : 'Activate'}
            </button>
          </li>
        ))}
      </ul>

      {/* Botão para resetar rankings */}
      <button onClick={() => setShowConfirmReset(true)} style={{ marginTop: '20px', backgroundColor: 'red', color: 'white' }}>
        Resetar Rankings
      </button>

      {/* Pop-up de confirmação */}
      {showConfirmReset && (
        <div className="confirmation-modal">
          <p>Tem certeza de que deseja resetar todos os rankings de usuário?</p>
          <button onClick={handleResetRankings} style={{ backgroundColor: 'green', color: 'white' }}>Sim</button>
          <button onClick={() => setShowConfirmReset(false)} style={{ backgroundColor: 'red', color: 'white' }}>Não</button>
        </div>
      )}
    </div>
  );
};

export default SeasonManagement;
