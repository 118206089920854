import React, { useState } from 'react';
import '../styles/mapDetails.css';

const MapDetails = ({ selectedMap }) => {
  const [hoveredNftIndex, setHoveredNftIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredNftIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredNftIndex(null);
  };

  const formatRaceTime = (seconds) => {
    if (seconds < 60) {
      return `${seconds}s`;
    }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes < 60) {
      return `${minutes}m ${remainingSeconds}s`;
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours < 24) {
      return `${hours}h ${remainingMinutes}m ${remainingSeconds}s`;
    }

    const days = Math.floor(hours / 24);
    const remainingHours = hours % 24;

    return `${days}d ${remainingHours}h ${remainingMinutes}s`;
  };

  const totalCoins = selectedMap.totalCoins && selectedMap.totalCoins.$numberDecimal
    ? parseFloat(selectedMap.totalCoins.$numberDecimal)
    : 0;

  return (
    <div className="custom-map-details">
      <div className="custom-map-selected">{selectedMap.name}</div>

      <div className="custom-map-info">
        <h4>Map Details:</h4>

        {/* Adicionando a exibição da descrição */}
        <div className="custom-map-description">
          <strong>Description:</strong> {selectedMap.description || 'No description available'}
        </div>

        <div className="custom-map-race-time">Race Time: {formatRaceTime(selectedMap.raceTime)}</div>
        <div className="custom-map-racers">Racers: {selectedMap.bots}</div>
        <div className="custom-map-total-coins">
          Total Coins: {!isNaN(totalCoins) ? totalCoins.toFixed(2) : '0.00'}
        </div>

        {selectedMap.nfts && selectedMap.nfts.length > 0 && (
          <div className="nfts-available-text">NFTs Available</div>
        )}

        <div className="custom-nft-slot-container">
          {selectedMap.nfts && selectedMap.nfts.map((nft, index) => (
            <div
              key={index}
              className="custom-nft-slot"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {nft.img ? (
                <img src={`https://ipfs.io/ipfs/${nft.img}`} alt={nft.name} className="custom-nft-image" />
              ) : (
                <div>Image not available</div>
              )}
              <div className="custom-nft-name">{nft.name || 'Name not available'}</div>

              {hoveredNftIndex === index && nft.attributes && (
                <div className="mapdetails-nft-tooltip">
                  <div className="mapdetails-nft-tooltip-header">Attributes</div>
                  <ul className="mapdetails-nft-tooltip-list">
                    {nft.attributes.map((attr, i) => (
                      <li key={i} className="mapdetails-nft-tooltip-item">
                        <strong className="attribute-name">{attr.trait_type}</strong>: <span className="attribute-value">{attr.value}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MapDetails;
