import Cookies from 'js-cookie';
import config from '../config';

const API_BASE_URL = `${config.apiBaseUrl}/api`;

// URLs específicas para a testnet do WAX
const WAX_API_ENDPOINTS = {
  FETCH_NFTS: 'https://wax.api.atomicassets.io/atomicassets/v1/assets',
  FETCH_NFT_DETAILS: 'https://wax.api.atomicassets.io/atomicassets/v1/assets',
  PXRS_BALANCE: 'https://wax.greymass.com/v1/chain/get_currency_balance' // AJUSTAR TAMBEM O NOME DO CONTRATO E O SIMBOLO!!
};

const getAuthToken = () => {
  const token = Cookies.get('authToken');
  console.log('Auth Token:', token); // Log para verificar o token
  return token;
};

const getCsrfToken = () => {
  const token = Cookies.get('csrfToken');
  console.log('CSRF Token:', token); // Log para verificar o token
  return token;
};

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));  // Função de atraso

export const fetchGlobalValues = async () => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/protected/globalvalues`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch global values');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateGlobalValues = async (globalValues, token, csrfToken) => {
  return fetch(`${config.apiBaseUrl}/api/protected/globalvalues`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'CSRF-Token': csrfToken,
    },
    body: JSON.stringify(globalValues),
    credentials: 'include',
  });
};

export const fetchMaps = async () => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/protected/mapsadded`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch maps');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchRacingCoins = async (userAccount) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ walletAddress: userAccount }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch RacingCoins');
    }

    const data = await response.json();
    return data.user.racingCoins || 0;
  } catch (error) {
    throw error;
  }
};

export const updateRacingCoins = async (userAccount, additionalRacingCoins) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/auth/racingcoins/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ walletAddress: userAccount, racingCoins: additionalRacingCoins }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to update RacingCoins');
    }

    const data = await response.json();
    return data.racingCoins;
  } catch (error) {
    throw error;
  }
};


const fetchWithRetry = async (url, options = {}, retries = 3, backoff = 3000) => {
  for (let i = 0; i < retries; i++) {
      try {
          const response = await fetch(url, options);
          if (!response.ok) {
              if (response.status === 429 && i < retries - 1) {  // Se receber erro 429
                  await delay(backoff);  // Atraso exponencial
                  backoff *= 2;  // Aumenta o backoff exponencialmente
                  continue;  // Tente novamente
              } else {
                  throw new Error(`HTTP error! status: ${response.status}`);
              }
          }
          return await response.json();
      } catch (error) {
          if (i === retries - 1) throw error;  // Se for a última tentativa, lance o erro
      }
  }
};

export const fetchNFTs = async (userAccount) => {
  let allNfts = [];
  let page = 1;
  const limit = 50;  // Limite de NFTs por página
  let hasMore = true;

  try {
      while (hasMore) {
          const url = `${WAX_API_ENDPOINTS.FETCH_NFTS}?collection_name=pixelracesgo&schema_name=cars&owner=${userAccount}&page=${page}&limit=${limit}`;
          const data = await fetchWithRetry(url);

          if (data && data.data && data.data.length > 0) {
              allNfts = [...allNfts, ...data.data];
              page += 1;

              // Adicionando um pequeno atraso entre as requisições
              await delay(500);  
          } else {
              hasMore = false;
          }
      }
      return allNfts;
  } catch (error) {
      throw error;
  }
};

export const fetchOngoingRaces = async (userAccount) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/races/ongoing?walletAddress=${userAccount}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch ongoing races');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveReward = async (reward) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/rewards/save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify(reward),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to save reward');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getRewards = async (userAccount) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/rewards/get`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ userAccount }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch rewards');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const collectRewards = async (userAccount) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/rewards/collect`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ userAccount }),
      credentials: 'include'
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Erro na resposta do servidor:', errorText);
      throw new Error('Failed to collect rewards');
    }

    const data = await response.json();
    console.log('Dados recebidos da coleta de recompensas:', data);

    // Retorna os dados completos para serem usados na RewardsModal
    return data;
  } catch (error) {
    console.error('Error in collectRewards:', error);
    throw error;
  }
};


export const fetchUser = async (walletAddress) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/users/${walletAddress}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkCarRaceStatus = async (carId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/races/ongoing?carId=${carId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to check car race status');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkCarInRace = async (carId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/checkCarInRace/${carId}`);

    if (!response.ok) {
      throw new Error(`Erro na verificação do status da corrida: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return { inRace: false };
  }
};

export const deleteMap = async (mapId) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/mapsadded/${mapId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to delete map');
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting map:', error);
    throw error;
  }
};

// Nova função para buscar o ranking
export const fetchRanking = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/ranking`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch ranking');
    }

    const data = await response.json();
    // Ensure we only display users with XP > 0
    console.log(data.length); // Log the number of users fetched for debugging
    return data;
  } catch (error) {
    console.error('Failed to fetch ranking:', error);
    throw error;
  }
};


export const createSeason = async (seasonData) => {
  const token = getAuthToken();  // Retrieve auth token
  const csrfToken = getCsrfToken();  // Retrieve CSRF token

  console.log('Creating season with data:', seasonData); // Log the data being sent

  const response = await fetch(`${API_BASE_URL}/seasons`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,  // Include auth token
      'CSRF-Token': csrfToken,  // Include CSRF token
    },
    body: JSON.stringify(seasonData),
    credentials: 'include',  // Include cookies
  });

  if (!response.ok) {
    const errorText = await response.text(); // Get error text for debugging
    console.error('Failed to create season:', errorText);
    throw new Error('Failed to create season');
  }

  return response.json();
};

export const fetchSeasons = async () => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/seasons`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch seasons');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateSeason = async (seasonId, updates) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/seasons/${seasonId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify(updates),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to update season');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteSeason = async (seasonId) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/seasons/${seasonId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to delete season');
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchActiveSeason = async () => {
  const response = await fetch(`${API_BASE_URL}/seasons/active`, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Failed to fetch active season');
  }

  return response.json();
};

// Função para verificar quais NFTs estão em stake
export const checkStake = async () => {
  try {
      const token = getAuthToken();
      const csrfToken = getCsrfToken();

      console.log('Enviando requisição para /stake/check com token:', token); // Log do token

      const response = await fetch(`${API_BASE_URL}/stake/check`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          credentials: 'include'
      });

      console.log('Resposta recebida de /stake/check:', response.status); // Log da resposta da API

      if (!response.ok) {
          console.error('Erro na verificação dos NFTs em stake:', response.statusText); // Log de erro
          throw new Error('Failed to check staked NFTs');
      }

      const data = await response.json();
      console.log('Dados de NFTs em stake recebidos:', data); // Log dos dados recebidos
      return data; // Certifique-se de que o retorno está correto
  } catch (error) {
      console.error('Erro na função checkStake:', error); // Log do erro
      throw error;
  }
};

// Função para enviar NFTs para stake
export const createStake = async (nftIds, walletAddress) => {
  try {
      const token = getAuthToken();
      const csrfToken = getCsrfToken();
      const response = await fetch(`${API_BASE_URL}/stake`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          body: JSON.stringify({ nfts: nftIds, walletAddress }), // Adiciona 'walletAddress' ao corpo da requisição
          credentials: 'include'
      });

      if (!response.ok) {
          const errorData = await response.json().catch(() => {
              // Caso a resposta não seja JSON
              return { message: 'Erro desconhecido no servidor', details: response.statusText };
          });
          console.error('Erro no servidor:', errorData); // Log detalhado do erro retornado pelo servidor
          throw new Error(errorData.message || 'Failed to stake NFTs');
      }

      const data = await response.json();
      return data;
  } catch (error) {
      throw error;
  }
};

export const getStakeCoins = async () => {
  try {
      const token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/stake/coins`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': getCsrfToken()
          },
          credentials: 'include'
      });

      if (!response.ok) {
          throw new Error('Failed to fetch stake coins');
      }

      const data = await response.json();
      return { stakeCoins: data.stakeCoins, lastCollected: data.lastCollected };
  } catch (error) {
      console.error("Error fetching stake coins:", error);
      throw error;
  }
};

//manipular dados do stakeconfigs da db
export const fetchStakeConfig = async () => {
  try {
      const token = getAuthToken();
      const csrfToken = getCsrfToken();
      const response = await fetch(`${API_BASE_URL}/stakeconfig`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          credentials: 'include'
      });

      if (!response.ok) throw new Error('Failed to fetch stake config');

      return await response.json();
  } catch (error) {
      throw error;
  }
};

// Função para atualizar a configuração de stake
export const updateStakeConfig = async (configData) => {
  try {
      const token = getAuthToken();
      const csrfToken = getCsrfToken();
      const response = await fetch(`${API_BASE_URL}/stakeconfig`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          body: JSON.stringify(configData),
          credentials: 'include'
      });

      if (!response.ok) {
          throw new Error('Failed to update stake config');
      }

      return await response.json();
  } catch (error) {
      console.error('Erro ao atualizar configuração de stake:', error);
      throw error;
  }
};


// Função para buscar as configurações de verificação de posse de NFTs
export const fetchVerificationSettings = async () => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/globalvalues/verification-settings`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch verification settings');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

// Função para alternar o uso do Eosjs
export const toggleEosjsVerification = async (enabled) => {
  const token = getAuthToken();
  const csrfToken = getCsrfToken();
  const response = await fetch(`${config.apiBaseUrl}/api/globalvalues/eosjs-toggle`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ enabled }),
      credentials: 'include'
  });

  if (!response.ok) throw new Error('Failed to toggle Eosjs verification');

  return await response.json();
};

// Função para alternar o uso do backend para verificação
export const toggleBackendVerification = async (enabled) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/globalvalues/backend-toggle`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ enabled }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to toggle backend verification');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};


// Função para remover NFTs do stake
export const removeStake = async (nftIds) => {
  console.log("Iniciando remoção de NFTs do stake...", nftIds);

  try {
      const token = getAuthToken(); // Supondo que getAuthToken retorne 'Bearer <token>'
      const csrfToken = getCsrfToken();
      const response = await fetch(`${API_BASE_URL}/stake/remove`, {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          body: JSON.stringify({ nfts: nftIds }),
          credentials: 'include'
      });

      if (!response.ok) {
          console.error("Erro na resposta ao tentar remover NFTs do stake:", response.statusText);
          throw new Error('Failed to remove NFTs from stake');
      }

      console.log("NFTs removidos do stake com sucesso.");
      return await response.json();
  } catch (error) {
      console.error("Erro ao remover NFTs do stake:", error);
      throw error;
  }
};

// Função para buscar detalhes do NFT pelo ID
export const fetchNFTDetails = async (nftId) => {
  try {
      const url = `${WAX_API_ENDPOINTS.FETCH_NFT_DETAILS}/${nftId}`;
      const response = await fetch(url);
      const data = await response.json();
      return data.data;
  } catch (error) {
      throw error;
  }
};

// Função para depositar saldo no stake
export const depositStake = async (amount) => {
  try {
      const token = getAuthToken();
      const csrfToken = getCsrfToken();
      const response = await fetch(`${config.apiBaseUrl}/stakeconfig/deposit`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          body: JSON.stringify({ newBalance: amount }),  // Certifique-se de que "newBalance" é enviado
          credentials: 'include'
      });

      if (!response.ok) {
          throw new Error('Failed to update stake config');
      }

      return await response.json();
  } catch (error) {
      console.error('Erro ao depositar saldo no stake:', error);
      throw error;
  }
};

// Nova função para buscar saldo de PXRS
export const fetchPXRSBalance = async (userAccount) => {
  try {
    console.log(`Fetching PXRS balance for account: ${userAccount}`);

    const response = await fetch(WAX_API_ENDPOINTS.PXRS_BALANCE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: 'pixelracesmg',
        account: userAccount,
        symbol: 'PXRS'
      })
    });

    console.log('Response status:', response.status);
    
    const data = await response.json();
    console.log('Raw data:', data);

    if (Array.isArray(data) && data.length > 0) {
      const balanceString = data[0];
      console.log('Balance string:', balanceString);
      
      const [amount] = balanceString.split(' ');
      const balance = parseFloat(amount);
      console.log('Parsed balance:', balance);
      
      return balance;
    }

    console.log('No balance found or data array is empty');
    return 0;
  } catch (error) {
    console.error('Failed to fetch PXRS balance:', error);
    return 0;
  }
};


export const fetchItems = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/items`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAuthToken()}`,
        'CSRF-Token': getCsrfToken()
      },
      credentials: 'include'
    });
    if (!response.ok) {
      throw new Error('Failed to fetch items');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const createItem = async (itemData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/items`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAuthToken()}`,
        'CSRF-Token': getCsrfToken()
      },
      body: JSON.stringify(itemData), // itemData agora inclui isCollection
      credentials: 'include'
    });
    if (!response.ok) {
      throw new Error('Failed to create item');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteItem = async (itemId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/items/${itemId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAuthToken()}`,
        'CSRF-Token': getCsrfToken()
      },
      credentials: 'include'
    });
    if (!response.ok) {
      throw new Error('Failed to delete item');
    }
  } catch (error) {
    throw error;
  }
};

export const fetchFilteredNFTs = async (userAccount) => {
  try {
    const response = await fetch(`${API_BASE_URL}/filtered-nfts?userAccount=${userAccount}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAuthToken()}`,
        'CSRF-Token': getCsrfToken()
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch filtered NFTs');
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};


export const collectStakeCoins = async (userAccount) => {
  const token = getAuthToken();
  const csrfToken = getCsrfToken();

  try {
      const response = await fetch(`${API_BASE_URL}/stake/collect`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'CSRF-Token': csrfToken
          },
          body: JSON.stringify({ userAccount }),
          credentials: 'include'
      });

      if (response.status === 400) {
          const data = await response.json();
          return { error: true, message: data.message };
      } else if (!response.ok) {
          return { error: true, message: 'Unexpected error occurred' };
      }

      const data = await response.json();
      return { error: false, totalCoins: data.totalCoins };
  } catch (error) {
      return { error: true, message: 'Network or unexpected error' };
  }
};


export const checkItemInRace = async (itemId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/checkItemInRace/${itemId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAuthToken()}`,
        'CSRF-Token': getCsrfToken()
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to check item race status');
    }

    const data = await response.json();
    return data.inRace; // Ensure this is returning the correct boolean value
  } catch (error) {
    console.error('Error checking item race status:', error);
    return false; // Return false if there's an error
  }
};


// Exemplo de como criar um mapa (createMap)
export const createMap = async (mapData) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${config.apiBaseUrl}/api/mapsadded`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken,
      },
      body: JSON.stringify(mapData),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to create map');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating map:', error);
    throw error;
  }
};

export const updateItem = async (itemId, itemData) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/items/${itemId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify(itemData), // itemData agora inclui isCollection
      credentials: 'include'
    });
    if (!response.ok) {
      throw new Error('Failed to update item');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

// Função para resetar rankings de todos os usuários
export const resetUserRankings = async (token) => {
  const csrfToken = getCsrfToken();
  const response = await fetch(`${API_BASE_URL}/reset-rankings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'CSRF-Token': csrfToken
    },
    credentials: 'include'
  });

  if (!response.ok) {
    throw new Error('Failed to reset rankings');
  }

  return await response.json();
};


export const fetchPublicItems = async () => {
  const response = await fetch(`${API_BASE_URL}/public/items`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error('Failed to fetch public items');
  }

  const data = await response.json();
  return data;
};


// Add function to distribute rewards
export const distributeSeasonRewardsManually = async (seasonId) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/seasons/distribute-rewards`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ seasonId }), // Ensure seasonId is included here
      credentials: 'include'
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Failed to distribute rewards:', errorText); // Log response error text
      throw new Error('Failed to distribute rewards');
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

// Add function to update season status
export const updateSeasonStatus = async (seasonId, status) => {
  try {
    const token = getAuthToken();
    const csrfToken = getCsrfToken();
    const response = await fetch(`${API_BASE_URL}/seasons/update-status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify({ seasonId, status }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to update season status');
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};