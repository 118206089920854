import React, { useState, useEffect } from 'react'; 
import '../styles/rewardsModal.css';
import { collectRewards, fetchMaps } from '../utils/api';
import seasontrophie from '../assets/seasontrophie.png'; // Import the image

const RewardsModal = ({
  showRewards,
  rewards,
  setShowRewards,
  userAccount,
  setNotificationMessage,
  setShowNotification,
  fetchPXRS, // Receiving fetchPXRS via props
}) => {
  const [loading, setLoading] = useState(false);
  const [maps, setMaps] = useState({});

  const fetchMapData = async () => {
    try {
      const mapsData = await fetchMaps();
      const mapsDict = mapsData.reduce((acc, map) => {
        acc[map._id] = map;
        return acc;
      }, {});
      setMaps(mapsDict);
    } catch (error) {
      console.error('Error fetching maps:', error.message);
    }
  };

  useEffect(() => {
    fetchMapData();
  }, []);

  // Log to check if fetchPXRS is defined
  console.log('RewardsModal: fetchPXRS', fetchPXRS);

  const handleCollectRewards = async () => {
    if (loading) return;
    setLoading(true);

    try {
      console.log('Starting reward collection...');

      if (rewards.length === 0) {
        console.log('No rewards available for collection.');
        setNotificationMessage('No rewards available for collection.');
        setShowNotification(true);
        setLoading(false);
        return;
      }

      // Calling the collectRewards function and receiving the data
      const data = await collectRewards(userAccount);
      console.log('Data received from collectRewards:', data);

      // Check if the collection was successful
      if (data && data.message === 'Rewards successfully collected') {
        console.log('Rewards successfully collected.');
        setNotificationMessage('Rewards successfully collected!');

        // Updating PXRS balance by calling fetchPXRS
        if (fetchPXRS && typeof fetchPXRS === 'function') {
          console.log('Calling fetchPXRS from RewardsModal');
          await fetchPXRS();
          console.log('PXRS balance updated via fetchPXRS.');
        } else {
          console.log('fetchPXRS is not defined or is not a function.');
        }
      } else {
        // If the response is not successful
        console.error('Error: Invalid response from reward collection.');
        setNotificationMessage('Error collecting rewards. Please try again.');
      }

      setShowNotification(true);
      setShowRewards(false);
    } catch (error) {
      console.error('Error collecting rewards:', error);

      setNotificationMessage('Error collecting rewards. Please try again.');
      setShowNotification(true);
    } finally {
      setLoading(false);
      console.log('Reward collection process completed.');
    }
  };

  return (
    <>
      <div className="rewards-overlay" onClick={() => setShowRewards(false)}></div>
      <div className={`rewards-modal ${showRewards ? 'show' : ''}`}>
        <button className="close-button" onClick={() => setShowRewards(false)}>
          ✖
        </button>
        <div className="rewards-header">
          <h2>REWARDS</h2>
          <button className="collect-all-button" onClick={handleCollectRewards} disabled={loading}>
            {loading ? 'Collecting...' : 'Collect All'}
          </button>
        </div>
        <div className="rewards-list">
          {rewards.map((reward, index) => {
            const mapDetails = maps[reward.mapId] || {};

            // Log to check the value of reward.coins
            console.log(`Reward ${index} - coins:`, reward.coins);
            console.log(`Type of reward.coins:`, typeof reward.coins);

            // Extract the correct value from the $numberDecimal object
            const rewardCoins = reward.coins && reward.coins.$numberDecimal
              ? parseFloat(reward.coins.$numberDecimal)
              : parseFloat(reward.coins);

            return (
              <div key={index} className={`reward-item ${reward.seasonName ? 'ranking-reward' : ''}`}>
                {reward.seasonName ? (
                  <>
                    <div className="season-trophy-container">
                      {/* Add the trophy image */}
                      <img src={seasontrophie} alt="Season Trophy" className="season-trophy-image" />
                      <div className="reward-ranking-season">{reward.seasonName}</div>
                    </div>
                    <div className="reward-details">
                      <div className="reward-ranking">Ranking: {reward.position}</div>
                      <div className="reward-coins-rewardmodal">PXRS: {rewardCoins.toFixed(2)}</div>
                      <div className="reward-ranking-nfts">
                        <div className="reward-ranking-nft-grid">
                          {[...Array(3)].map((_, i) =>
                            reward.nfts[i] ? (
                              <div key={i} className="reward-ranking-nft-slot">
                                <img
                                  src={`https://ipfs.io/ipfs/${reward.nfts[i].img}`}
                                  alt={reward.nfts[i].name}
                                  className="reward-ranking-nft-image"
                                />
                                <div className="reward-ranking-nft-amount">x{reward.nfts[i].amount}</div>
                              </div>
                            ) : (
                              <div key={i} className="reward-ranking-nft-slot empty-ranking-nft-slot"></div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="reward-columns">
                    <div className="reward-column reward-map-column">
                      <img
                        src={mapDetails.image || 'path/to/default/map-image.png'}
                        alt="Map"
                        className="reward-map-image"
                      />
                      <div className="rewardmodal-map-name">{mapDetails.name || 'Unknown Map'}</div>
                    </div>

                    <div className="reward-column reward-details-column">
                      <img
                        src={`https://ipfs.io/ipfs/${reward.carImage}`}
                        alt="Car"
                        className="reward-car-image"
                      />
                      <div className="reward-car-name">{reward.carName}</div>
                      <div className="reward-position">Position: {reward.position}</div>
                      <div className="reward-pxrs">PXRS: {rewardCoins.toFixed(2)}</div>
                    </div>

                    <div className="reward-column reward-nfts-column">
                      <div className="reward-nft-grid">
                        {[...Array(6)].map((_, i) =>
                          reward.nfts[i] ? (
                            <div key={i} className="reward-nft-slot">
                              <img
                                src={`https://ipfs.io/ipfs/${reward.nfts[i].img}`}
                                alt={reward.nfts[i].name}
                                className="reward-nft-image"
                              />
                              <div className="reward-nft-amount">x{reward.nfts[i].amount}</div>
                            </div>
                          ) : (
                            <div key={i} className="reward-nft-slot empty-nft-slot"></div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RewardsModal;
