const config = {
  apiBaseUrl: 'https://pixelraces.com',
  websocketUrl: 'ws://pixelraces.com',

  //apiBaseUrl: 'http://localhost:5000',
  //websocketUrl: 'ws://localhost:5000/socket.io',
};

export default config;

  //apiBaseUrl: 'http://localhost:5000',
  //websocketUrl: 'ws://localhost:5000/socket.io',