// HomeGame.js

import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
  fetchGlobalValues,
  fetchMaps,
  fetchRacingCoins,
  fetchNFTs,
  fetchOngoingRaces,
  getRewards,
  collectRewards as collectRewardsApi,
  fetchUser,
  checkCarInRace,
  fetchPXRSBalance,
} from '../utils/api';
import { startRace } from '../utils/raceActions';
import { formatTime } from '../utils/formatTime';
import io from 'socket.io-client';
import AdminPanel from '../components/adminpanel/AdminPanel';
import CarDisplay from '../components/CarDisplay';
import MapDisplay from '../components/MapDisplay';
import MapDetails from '../components/MapDetails';
import NFTSelector from '../components/NFTSelector';
import OngoingRaces from '../components/OngoingRaces';
import RewardsModal from '../components/RewardsModal';
import HeaderGame from '../components/HeaderGame';
import Notification from '../components/Notification';
import RaceStartAnimation from '../components/RaceStartAnimation';
import ItemSelection from '../components/ItemSelection';
import FooterGame from '../components/FooterGame';
import ProfileImageSelector from '../components/ProfileImageSelector';
import '../styles/fonts.css';
import '../styles/global.css';
import '../styles/header.css';
import '../styles/sidebar.css';
import '../styles/carDisplay.css';
import '../styles/mapsSection.css';
import '../styles/attributes.css';
import '../styles/adminPanel.css';
import '../styles/nftSelector.css';
import '../styles/ongoingRaces.css';
import '../styles/rewardsModal.css';
import '../styles/startGame.css';
import '../styles/raceAnimation.css';
import '../styles/HomeGame.css';
import '../styles/ProfileImageSelector.css';
import config from '../config';
import defaultAvatar from '../assets/profile/default.png'; // Atualize para o caminho correto

const socket = io(config.websocketUrl, { transports: ['websocket', 'polling', 'flashsocket'] });

const HomeGame = () => {
  const { userAccount, isAdmin, setRacingCoins, loading } = useAuth();
  const [showAdminPanel, setShowAdminPanel] = useState(false);
  const [maps, setMaps] = useState([]);
  const [currentMapIndex, setCurrentMapIndex] = useState(0);
  const [selectedCar, setSelectedCar] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [showSelectCar, setShowSelectCar] = useState(false);
  const [selectedMap, setSelectedMap] = useState(null);
  const [raceTime, setRaceTime] = useState(0);
  const [carUsage, setCarUsage] = useState(JSON.parse(localStorage.getItem('carUsage')) || {});
  const [showOngoingRaces, setShowOngoingRaces] = useState(false);
  const [ongoingRaces, setOngoingRaces] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showRewards, setShowRewards] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [racesCompleted, setRacesCompleted] = useState(0);
  const [raceLimit, setRaceLimit] = useState(500);
  const [resetTimer, setResetTimer] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [showRaceAnimation, setShowRaceAnimation] = useState(false);
  const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(false);
  const [showItemSelection, setShowItemSelection] = useState(false);
  const [equippedNFTs, setEquippedNFTs] = useState([]);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isAttributesVisible, setIsAttributesVisible] = useState(true);

  // Novo estado para pxrsBalance
  const [pxrsBalance, setPxrsBalance] = useState(0);

  // Importação do avatar padrão
  // Já importado no topo

  // Inicializar o estado de selectedProfileImage com o avatar padrão
  const [selectedProfileImage, setSelectedProfileImage] = useState(defaultAvatar);
  const [showProfileImageSelector, setShowProfileImageSelector] = useState(false);

  // Importar dinamicamente todas as imagens de perfil
  const importAllImages = (r) => r.keys().map(r);
  const profileImages = importAllImages(require.context('../assets/profile', false, /\.(png|jpe?g|svg)$/));

  const updateDailyRaceLimit = (newLimit) => {
    if (typeof newLimit === 'number' && !isNaN(newLimit)) {
      setRaceLimit(newLimit);
    }
  };

  const resetRaceState = (resetSelectedCar, setRaceTime, setCarUsage, selectedCar) => {
    resetSelectedCar();
    setRaceTime(0);
    setCarUsage((prevUsage) => ({
      ...prevUsage,
      [selectedCar.asset_id]: {
        ...prevUsage[selectedCar.asset_id],
        raceTime: 0,
      },
    }));
  };

  // Definir a função fetchPXRS
  const fetchPXRS = useCallback(async () => {
    console.log('fetchPXRS foi chamado no HomeGame');
    if (userAccount) {
      try {
        const balance = await fetchPXRSBalance(userAccount);
        console.log('Saldo PXRS atualizado:', balance);
        setPxrsBalance(balance);
      } catch (error) {
        console.error('Erro ao buscar saldo PXRS:', error);
      }
    }
  }, [userAccount]);

  // Buscar saldo de PXRS ao montar o componente e quando userAccount mudar
  useEffect(() => {
    fetchPXRS();
  }, [fetchPXRS]);

  useEffect(() => {
    const loadData = async () => {
      if (!userAccount) return;

      try {
        const globalValues = await fetchGlobalValues();
        setRaceLimit(globalValues.raceLimit || 500);
        setResetTimer(globalValues.resetTimer);

        const mapsData = await fetchMaps();
        setMaps(mapsData);

        const coins = await fetchRacingCoins(userAccount);
        setRacingCoins(coins);

        const user = await fetchUser(userAccount);
        setRacesCompleted(user.racesCompleted || 0);
      } catch (error) {
        console.error('Error loading data:', error);
        setNotificationMessage('Error loading initial data.');
        setShowNotification(true);
      }
    };

    loadData();

    socket.on('map_expired', (mapId) => {
      setMaps((prevMaps) => prevMaps.filter((map) => map._id !== mapId));
    });

    socket.on('map_timer', ({ id, duration }) => {
      setMaps((prevMaps) => prevMaps.map((map) => (map._id === id ? { ...map, duration } : map)));
    });

    socket.on('map_deleted', (mapId) => {
      setMaps((prevMaps) => prevMaps.filter((map) => map._id !== mapId));
    });

    socket.on('reset_race_limits', ({ newResetTimer, raceLimit }) => {
      setResetTimer(newResetTimer);
      updateDailyRaceLimit(raceLimit);
      setRacesCompleted(0);
    });

    return () => {
      socket.off('map_expired');
      socket.off('map_timer');
      socket.off('map_deleted');
      socket.off('reset_race_limits');
    };
  }, [userAccount, setRacingCoins]);

  // Carregar a imagem de perfil correta quando userAccount muda
  useEffect(() => {
    if (userAccount) {
      const storedImage = localStorage.getItem(`profileImage_${userAccount}`);
      setSelectedProfileImage(storedImage || defaultAvatar);
    } else {
      setSelectedProfileImage(defaultAvatar);
    }
  }, [userAccount, defaultAvatar]);

  const handleSelectCar = () => {
    setShowSelectCar(true);
    fetchNFTs(userAccount)
      .then(setNfts)
      .catch((error) => {
        console.error('Error fetching NFTs:', error);
        setNotificationMessage('Error loading NFTs.');
        setShowNotification(true);
      });
  };

  const handleSelectNFT = (nft) => {
    setSelectedCar(nft);
    setShowSelectCar(false);
  };

  const resetSelectedCar = () => {
    setSelectedCar(null);
  };

  const resetEquippedNFTs = () => {
    setEquippedNFTs([]);
  };

  const handleStartRace = async () => {
    setNotificationMessage('');
    setShowNotification(false);

    console.log('Verificando se a corrida pode ser iniciada...');

    if (racesCompleted >= raceLimit) {
      setNotificationMessage('Race limit reached. Wait for the reset timer to race again.');
      setShowNotification(true);
      return;
    }

    if (!selectedCar && !selectedMap) {
      setNotificationMessage('No car and map selected. Please select both before starting the race.');
      setShowNotification(true);
      return;
    }

    if (!selectedCar) {
      setNotificationMessage('No car selected. Please select a car before starting the race.');
      setShowNotification(true);
      return;
    }

    if (!selectedMap) {
      setNotificationMessage('No map selected. Please select a map before starting the race.');
      setShowNotification(true);
      return;
    }

    setIsStartButtonDisabled(true);
    setShowRaceAnimation(true);

    try {
      console.log('Iniciando animação da corrida...');

      const animationDuration = 5500;

      setTimeout(async () => {
        try {
          console.log('Verificando status do carro com ID:', selectedCar.asset_id);
          const status = await checkCarInRace(selectedCar.asset_id);
          console.log('Status retornado de checkCarInRace:', status);

          if (status?.inRace) {
            setNotificationMessage('The car is already in an ongoing race.');
            setShowNotification(true);
            setIsStartButtonDisabled(false);
            setShowRaceAnimation(false);
            return;
          }

          console.log('Iniciando corrida com os dados:', {
            selectedCar,
            selectedMap,
            userAccount,
          });

          const data = await startRace(
            selectedCar,
            selectedMap,
            userAccount,
            carUsage,
            setCarUsage,
            setRaceTime,
            setErrorMessage,
            setRacesCompleted,
            resetSelectedCar,
            equippedNFTs,
            resetEquippedNFTs
          );

          console.log('Dados da corrida iniciada recebidos:', data);

          setNotificationMessage('Race started successfully!');
          setShowNotification(true);

          setTimeout(async () => {
            resetRaceState(resetSelectedCar, setRaceTime, setCarUsage, selectedCar);
            resetEquippedNFTs();

            const user = await fetchUser(userAccount);
            console.log('Usuário atualizado:', user);
            setRacesCompleted(user?.racesCompleted || 0);
          }, 1000);
        } catch (error) {
          console.error('Erro ao iniciar a corrida:', error);
          const errorMessage = error.message || 'Erro desconhecido ao iniciar a corrida.';
          setNotificationMessage(errorMessage);
          setShowNotification(true);
        } finally {
          setShowRaceAnimation(false);
          setIsStartButtonDisabled(false);
          console.log('Corrida finalizada - animação parada.');
        }
      }, animationDuration);
    } catch (error) {
      console.error('Erro ao iniciar a corrida:', error);
      setNotificationMessage(error.message || 'Erro desconhecido.');
      setShowNotification(true);
    }
  };

  const handleSelectMap = (map) => {
    setSelectedMap(map);
  };

  const handleFetchOngoingRaces = () => {
    fetchOngoingRaces(userAccount)
      .then(setOngoingRaces)
      .catch((error) => {
        console.error('Error fetching ongoing races:', error);
        setNotificationMessage('Error loading ongoing races.');
        setShowNotification(true);
      });
  };

  const handleFetchRewards = () => {
    getRewards(userAccount)
      .then(setRewards)
      .catch((error) => {
        console.error('Error fetching rewards:', error);
        setNotificationMessage('Error loading rewards.');
        setShowNotification(true);
      });
  };

  const handleEquipNFT = (nft) => {
    setEquippedNFTs((prevNFTs) => [...prevNFTs, nft]);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const toggleAttributes = () => {
    setIsAttributesVisible((prevState) => !prevState);
  };

  // Handler to open the profile image selector
  const handleProfileImageClick = () => {
    setShowProfileImageSelector(true);
  };

  // Save selected profile image to localStorage and update state
  const handleSelectProfileImage = (image) => {
    setSelectedProfileImage(image);
    if (userAccount) {
      localStorage.setItem(`profileImage_${userAccount}`, image);
    }
    setShowProfileImageSelector(false);
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-text">Loading...</div>
      </div>
    );
  }

  return (
    <div className="home-game">
      <HeaderGame
        setShowAdminPanel={setShowAdminPanel}
        isAdmin={isAdmin}
        updateDailyRaceLimit={updateDailyRaceLimit}
        onClickProfileImage={handleProfileImageClick}
        selectedProfileImage={selectedProfileImage}
        pxrsBalance={pxrsBalance.toFixed(2)} // Exibir com duas casas decimais
        fetchPXRS={fetchPXRS}
        setShowRewards={setShowRewards}
        showRewards={showRewards}
        rewards={rewards}
        setNotificationMessage={setNotificationMessage}
        setShowNotification={setShowNotification}
      />
      <div className="main-content">
        <div className={`sidebar ${isSidebarVisible ? 'visible' : 'hidden'}`}>
          <div className="sidebar-content">
            <button
              className="icon-button rewards-button"
              onClick={() => {
                setShowRewards(true);
                handleFetchRewards();
              }}
            >
              <img src={require('../assets/rewards-icon.png')} alt="Rewards Icon" />
            </button>
            <button
              className="icon-button ongoing-races-button"
              onClick={() => {
                setShowOngoingRaces(true);
                handleFetchOngoingRaces();
              }}
            >
              <img src={require('../assets/ongoing-races-icon.png')} alt="Ongoing Races Icon" />
            </button>
            <button className="icon-button multiplayer-button">
              <img src={require('../assets/multiplayer-icon.png')} alt="Workshop Icon" />
            </button>
          </div>
        </div>
        <div className="game-area">
          <button className="toggle-sidebar-button" onClick={toggleSidebar}>
            {isSidebarVisible ? 'Menu' : 'Menu'}
          </button>
          <CarDisplay
            selectedCar={selectedCar}
            handleSelectCar={handleSelectCar}
            formatTime={formatTime}
            setNotificationMessage={setNotificationMessage}
            setShowNotification={setShowNotification}
            setShowItemSelection={setShowItemSelection}
            equippedNFTs={equippedNFTs}
            setEquippedNFTs={setEquippedNFTs}
          />
          <MapDisplay
            maps={maps}
            setMaps={setMaps}
            selectedMap={selectedMap}
            handleSelectMap={handleSelectMap}
            currentMapIndex={currentMapIndex}
            setCurrentMapIndex={setCurrentMapIndex}
          />
          {selectedMap && <MapDetails selectedMap={selectedMap} />}
          <button className="start-button" onClick={handleStartRace} disabled={isStartButtonDisabled}>
            START GAME
          </button>
          <FooterGame />
        </div>
        <div className={`attributes ${isAttributesVisible ? 'visible' : 'hidden'}`}>
          {selectedCar ? (
            <div className="attributes-content">
              <div className="attribute">
                Name: {selectedCar.template.immutable_data.name} (ID: {selectedCar.asset_id})
              </div>
              <div className="attribute">Car Rarity: {selectedCar.template.immutable_data['Car Rarity']}</div>
              <div className="attribute">Skin Rarity: {selectedCar.template.immutable_data['Skin Rarity']}</div>
              <div className="attribute">Speed Level: {selectedCar.template.immutable_data['Speed Level']}</div>
              <div className="attribute">Acceleration: {selectedCar.template.immutable_data['Acceleration']}</div>
              <div className="attribute">Nitro Level: {selectedCar.template.immutable_data['Nitro Level']}</div>
              <div className="attribute">Luck Level: {selectedCar.template.immutable_data['Luck Level']}</div>
            </div>
          ) : (
            <div className="attributes-content">
              <div className="attribute">No car selected</div>
            </div>
          )}
        </div>
        <button className="toggle-attributes-button" onClick={toggleAttributes}>
          {isAttributesVisible ? 'Attributes' : 'Attributes'}
        </button>
      </div>

      {showAdminPanel && <AdminPanel setShowAdminPanel={setShowAdminPanel} maps={maps} setMaps={setMaps} />}

      {showSelectCar && (
        <NFTSelector
          selectedCar={selectedCar}
          handleSelectNFT={handleSelectNFT}
          setShowSelectCar={setShowSelectCar}
          setNotificationMessage={setNotificationMessage}
          setShowNotification={setShowNotification}
        />
      )}

      {showOngoingRaces && (
        <OngoingRaces
          ongoingRaces={ongoingRaces}
          setShowOngoingRaces={setShowOngoingRaces}
          formatTime={formatTime}
          userAccount={userAccount}
        />
      )}

      {showRewards && (
        <RewardsModal
          showRewards={showRewards}
          rewards={rewards}
          setShowRewards={setShowRewards}
          userAccount={userAccount}
          setNotificationMessage={setNotificationMessage}
          setShowNotification={setShowNotification}
          fetchPXRS={fetchPXRS} // Passando fetchPXRS para o RewardsModal
        />
      )}

      {showNotification && (
        <Notification message={notificationMessage} setShowNotification={setShowNotification} />
      )}

      {showRaceAnimation && (
        <RaceStartAnimation
          onAnimationEnd={() => setShowRaceAnimation(false)}
          key={showRaceAnimation ? 'show' : 'hide'}
        />
      )}

      {showItemSelection && (
        <ItemSelection
          setShowNFTSelector={setShowItemSelection}
          handleEquipNFT={handleEquipNFT}
          equippedNFTs={equippedNFTs}
        />
      )}

      {showProfileImageSelector && (
        <ProfileImageSelector
          images={profileImages}
          onSelectImage={handleSelectProfileImage}
          onClose={() => setShowProfileImageSelector(false)}
          userAccount={userAccount} // Passando userAccount para o ProfileImageSelector
        />
      )}
    </div>
  );
};

export default HomeGame;
